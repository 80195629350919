<template>
  <ui-component-modal
    :modalTitle="'Edit exceptions'"
    :onClickCancel="onClickCancel"
    :showModal="showModal"
    :onClickSave="save"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :savingSuccessMessage="'Success! Data saved.'"
    :savingErrorMessage="'Oops! Something went wrong.'"
    :hideFooter="isSavingError || isSavingSuccess"
    @closeModal="onClickCancel"
  >
    <template v-slot:content>
      <table class="table is-striped is-fullwidth">
        <thead>
          <tr>
            <th>Day</th>
            <th>Date</th>
            <th>Time</th>
            <th>Open</th>
          </tr>
        </thead>
        <tbody>
          <ui-openingHours-row
            v-for="(openingHour, index) in openingHours"
            v-bind:openingHour="openingHour"
            :key="'t_' + index"
            :day="(new Date(openingHour.Date).getDay() + 1) | getDayName"
            :label="
              openingHour.Date
                | parseIsoDateStringToDate
                | dateObjectIsoDateString
            "
            :timeOptions="timeOptions"
          />
        </tbody>
      </table>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import openingsHoursProvider from '@/providers/openinghours'
import OpeningHoursRow from '@/components/UI/Form/OpeningHoursRow'

export default {
  name: 'modal-edit-default-opening-hours',

  components: {
    'ui-openingHours-row': OpeningHoursRow,
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    onClickCancel: {
      type: Function,
      required: true,
    },
    selectedMeetingtypeId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isSaving: false,
      isSavingError: false,
      isSavingSuccess: false,
      openingHours: this.$objectHelper.cleanSource(
        this.$store.getters['locationStore/getExceptionOpeningHours']
      ),
      timeOptions: this.buildTimeMinutesOptions(),
    }
  },

  computed: {
    ...mapState('locationStore', ['location']),
  },

  methods: {
    ...mapMutations('locationStore', ['setExceptionOpeningHours']),

    /**
     * Build select time options
     */
    buildTimeMinutesOptions(open = 0, close = 1440) {
      let minuteSteps = 15
      if (this.selectedMeetingtypeId === 1) {
        minuteSteps = 30
      }

      var output = []
      for (var i = open; i <= close; i = i + minuteSteps) {
        output.push(i)
      }
      return output
    },

    /**
     * Save opening hours
     */
    save() {
      let self = this
      this.isSaving = true
      openingsHoursProvider.methods
        .saveOpeninghoursDates(this.location.Id, this.openingHours)
        .then((response) => {
          self.setExceptionOpeningHours(self.openingHours)
          this.$emit('refresh-opening-hours') // refreshes opening hours in Dashboards -> Operation dashboard
          self.isSavingSuccess = true

          let t = setTimeout(() => {
            self.onClickCancel.call()
            clearTimeout(t)
          }, 2000)
        })
        .finally(() => {
          self.isSaving = false
        })
    },
  },
}
</script>

<style></style>
